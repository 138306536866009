import { useEffect, useState } from 'react';

import { validateEmail } from '@/utility/formHelpers';
import { t } from '@/utility/localization';
import { checkIsWebView } from '@/utility/webview';

import { signUpCheck } from '@/services/communitiesService';

import { useAuthContext } from '@/contexts/AuthContext';

import SocialLogins from '@/components/common/SocialLogins';
import NPLButton from '@/components/npl/NPLButton';
import NPLInput from '@/components/npl/NPLInput/NPLInput';

import { LOGIN_FORM_STATES } from '../LoginForm/constants';

const EmailForm = ({
  allowSignup = false, // Bool, if email entered does not exist in db, then a user will be signed up with default password if this prop is true. else will give an error that user does not exist
  formValues,
  setFormValues,
  setFormState,
  postLogin,
  signUpMiddleWare
}) => {
  const [email, setEmail] = useState(formValues.email);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState();
  const isWebView = checkIsWebView();

  const {
    isLoading,
    handleSocialAuth,
    socialLoginError,
    signupNewMemWithDefPwd
  } = useAuthContext();

  useEffect(() => {
    if (formValues?.email) {
      setEmail(formValues.email);
    }
  }, [formValues?.email]);

  const onSubmit = async (e) => {
    e.preventDefault();

    //client-side validation
    const isValid = await validateEmail(email);

    if (!isValid) {
      setEmailError(t('check-your-email-again'));
      return;
    }

    setIsSubmitting(true);
    // Check if it's an existing user or new user
    const { data, error } = await signUpCheck(email);

    if (error) {
      setEmailError(error);
      setIsSubmitting(false);
      return;
    }

    const { userExists, isActiveUser, token } = data;

    setFormValues((prevState) => ({
      ...prevState,
      email: email,
      isActiveUser
    }));

    if (!userExists) {
      if (signUpMiddleWare) {
        signUpMiddleWare(data);
        return;
      }
      if (allowSignup) {
        const res = await signupNewMemWithDefPwd({
          email: email,
          token
        });

        if (!res) {
          return;
        }

        return;
      }
      setEmailError(t('your-email-is-invalid-or-does-not-exist'));
      setIsSubmitting(false);
      return;
    }

    setFormState({
      current: LOGIN_FORM_STATES.OTP_FORM,
      previous: LOGIN_FORM_STATES.EMAIL_FORM
    });
  };

  const onFieldChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  return (
    <div className="c-EmailForm space-y-24">
      <form onSubmit={onSubmit} className="space-y-24">
        <NPLInput
          type="email"
          value={email}
          name="email"
          onInput={onFieldChange}
          placeholder={t('your-email-address')}
          error={emailError}
          testId="input-email"
        />

        <NPLButton
          stretch
          type="submit"
          hierarchy="accent_primary"
          size="lg"
          data-testid="login-button"
          loading={isLoading || isSubmitting}
          disabled={!email}
          buttonText={t('continue')}
        />
      </form>
      {!isWebView && (
        <>
          <div className="relative text-center">
            <div className="absolute w-full h-full flex items-center justify-center -z-1">
              <div className="border-b-1 border-npl-separator-alpha w-full"></div>
            </div>
            <span className="text-center bg-white-default px-8 py-12 text-label-md text-npl-text-secondary-on-light">
              {t('or1')}
            </span>
          </div>
          {/* Social Login */}
          <SocialLogins
            isLoading={isLoading}
            isRounded
            handleSocialAuth={async (params) => {
              const response = await handleSocialAuth(params);
              if (response.error) {
                setEmailError(response.error);
                return;
              }
              postLogin?.(response.data?.user);
            }}
            socialLoginError={socialLoginError}
          />
        </>
      )}
    </div>
  );
};

export default EmailForm;
